import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from './../../services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';
import { StatisticsService } from '../../services/statistics.service';
import { NgZone } from '@angular/core';


@Component({
  selector: 'login',
  styleUrls: ['./login.component.scss'],
  templateUrl: './login.component.html'
})
export class LoginComponent {
  returnUrl: string;
  isRequest: string;
  isChange: string;
  pwtoken: string;
  username: string;
  
  loading = false;
  submitted = false;
  hidePassword = true;
  loginForm: FormGroup;
  RequestResetForm: FormGroup;
  SetNewPasswordForm: FormGroup;
  error = '';

  constructor(private zone: NgZone, private formBuilder: FormBuilder,  private route: ActivatedRoute, private service: AuthenticationService, private router: Router, private _snackBar: MatSnackBar) {
    if (this.service.currentUserValue) { 
      console.log(this.service.currentUserValue);
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
        username: ['', Validators.required],
        password: ['', Validators.required]
    });

    this.RequestResetForm = this.formBuilder.group({
      username: ['', Validators.required]
    });

    this.SetNewPasswordForm = this.formBuilder.group({
      password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.pwtoken = this.route.snapshot.queryParams['pwtoken'] || '/';
    this.isRequest = this.route.snapshot.queryParams['isRequest'] || '/';
    this.isChange = this.route.snapshot.queryParams['isChange'] || '/';
    this.username = this.route.snapshot.queryParams['email'] || '/';
    console.log('------- returnUrl PARAM : ' + this.returnUrl);
    console.log('------- isRequest PARAM : ' + this.isRequest);
    console.log('------- isChange PARAM : ' + this.isChange);
    this.isRequestResetAction = (this.isRequest == 'true');
    this.isSetNewPasswordAction = (this.isChange == 'true');
    this.isLoginAction = (this.isChange != 'true') && (this.isRequest != 'true');
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }
  get f2() { return this.RequestResetForm.controls; }
  get f3() { return this.SetNewPasswordForm.controls; }

  isLoginAction: boolean = true;

  isRequestResetAction: boolean = false;

  isSetNewPasswordAction: boolean = false;
  isSetNewPasswordActionFinished: boolean = false;
  isSetNewPasswordSetFinished: boolean = false;


    onSubmit() {
    this.submitted = true;

    console.log('submit');
    
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.service.login(this.f.username.value, this.f.password.value)
      .pipe(first())
            .subscribe(
              data => {

                let sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
                (async () => {
                  await sleep(1000);
                  this.zone.run(() => {
                    this.router.navigate([this.returnUrl]);
                  });
                })();
                
                },
                error => {
                    this.error = error;
                    this.loading = false;
                });

    // .subscribe(
    //   (res: any) => {
    //     console.log(res);
    //     console.log('token: ' + res.token);

    //     localStorage.setItem('token', res.token);
    //     this.router.navigateByUrl('/');
    //   },
    //   err => {
    //     if (err.status == 400)
    //       console.log('400: ' + err);
    //     else
    //       console.log(err);
    //   }
    // );

  }


    onSubmitRequest() {
    this.submitted = true;

    console.log('onSubmitRequest');

    // stop here if form is invalid
    if (this.RequestResetForm.invalid) {
      return;
    }

    this.loading = true;


      this.service.RequestReset(this.f2.username.value).subscribe((res: any) => {
        this._snackBar.open('Mail skickat till angiven adress, öppna och följ instruktioner', '', {
          duration: 5 * 1000,
        });
        this.isRequestResetAction = false;
        this.isSetNewPasswordAction = false;
        this.isSetNewPasswordActionFinished = true;
        this.isSetNewPasswordSetFinished = false;
        this.isLoginAction = false;
        },
        error => {
          this._snackBar.open('Något gick fel, försök igen', '', {
            duration: 5 * 1000,
          });
          this.error = error;
          this.loading = false;
        });

  }

  onSubmitNewPassword() {
    this.submitted = true;

    console.log('onSubmitNewPassword');

    // stop here if form is invalid
    if (this.SetNewPasswordForm.invalid) {
      return;
    }

    this.loading = true;


    this.service.SetPassword(this.username, this.pwtoken, this.f3.password.value).subscribe((res: any) => {
      this._snackBar.open('Lösenordet har uppdaterats', '', {
        duration: 5 * 1000,
      });
      this.isSetNewPasswordSetFinished = true;
      this.isRequestResetAction = false;
      this.isSetNewPasswordAction = false;
      this.isLoginAction = false;
    },
      error => {
        this._snackBar.open('Något gick fel, försök igen', '', {
          duration: 5 * 1000,
        });
        this.error = error;
        this.loading = false;
      });

 

  }
}

