import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';


@Component({
  selector: 'resetpassword',
  styleUrls: ['./resetpassword.component.scss'],
  templateUrl: './resetpassword.component.html'
})
export class ResetPasswordComponent {


  returnUrl: string;
  loading = false;
  submitted = false;
  hidePassword = true;
  resetPasswordForm: FormGroup;
  error = '';

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router)
  { 

  }

  ngOnInit() {
    //this.loginForm = this.formBuilder.group({
    //    username: ['', Validators.required],
    //    password: ['', Validators.required]
    //});

    // get return url from route parameters or default to '/'
    //this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    //console.log('------- RETURN PARAM : ' + this.returnUrl);
  }

  // convenience getter for easy access to form fields
  //get f() { return this.loginForm.controls; }

  onSubmit() {
    //this.submitted = true;

    //console.log('submit');
    
    //// stop here if form is invalid
    //if (this.loginForm.invalid) {
    //  return;
    //}

    //this.loading = true;
    //this.service.login(this.f.username.value, this.f.password.value)
    //  .pipe(first())
    //        .subscribe(
    //            data => {
    //                this.router.navigate([this.returnUrl]);
    //            },
    //            error => {
    //                this.error = error;
    //                this.loading = false;
    //            });

    // .subscribe(
    //   (res: any) => {
    //     console.log(res);
    //     console.log('token: ' + res.token);

    //     localStorage.setItem('token', res.token);
    //     this.router.navigateByUrl('/');
    //   },
    //   err => {
    //     if (err.status == 400)
    //       console.log('400: ' + err);
    //     else
    //       console.log(err);
    //   }
    // );

  }
}

